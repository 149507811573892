import {
  ComparativeIcon,
} from '../../icons';
import { colors } from '../../styles/colors';
import { Container } from './styles';
import { t } from 'i18next';

export const NoGraph = (props): JSX.Element => {
  const { title, type } = props;
  return (
    <Container>
      <ComparativeIcon color={colors.Grey500} width="72" height="42" />
      <span style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '20px' }}>
        {title}
      </span>
      <span style={{ width: '250px', color: colors.Grey400, textAlign: 'center' }}>
        { type ? t('selecioneUmaDataIndiceDeUso') : t('selecioneUmaDataHistorico')}
      </span>
    </Container>
  ); };
