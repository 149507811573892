import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SuperiorBar = styled.div<{ size: number }>`
  background-color: #363BC4;
  border-radius: 4px 4px 0 0;

  width: 100%; // subtrair automação
  height: 7.5px;
`;

export const ListItemContainer = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: auto; // subtrair automação

  border: 1px solid rgba(0, 0, 0, 0.21);
  border-top: 0;
  border-radius: 0 0 4px 4px;
  height: 100%;
`;

export const ListItemDataContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  > div {
    padding-top: 15px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    min-height: 199px;
    width: 220px;
    height: 100%;
    margin-right: 18px;
    padding-left: 20px;
    &:first-child {
      margin-right: 0;
    }
  }

`;

export const Title = styled.strong`
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  margin: 10px 0 0 0;
  padding-bottom: 8px;
  border-bottom: 2px solid #DCDCDC;
`;

export const Title2 = styled.strong`
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  padding: 0 15px;
`;

export const TransparentLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
  line-height: normal;
  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
`;
