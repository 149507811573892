import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ButtonOptions = styled.div<{ disabled?: boolean }>(
  ({ disabled }) => `
  padding: 5px;
  display: flex;
  gap: 5px;
  min-width: 97px;
  flex-direction: row;
  height: max-content;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border-radius: 11px;
  border: 1px solid;
  border-color: #e3e3e3;
  cursor: pointer;

    ${disabled && `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`,
);

export const DataExportRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const ResultsResume = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  gap: 40px;

  border-radius: 9px;
  border: 1px solid rgba(232, 232, 232, 1);
  background-color: #f9f9f9;

  width: 100%;
  min-height: 120px;
  height: auto;
  flex-wrap: wrap;
  p{
    text-align: center;
  }
`;

export const UnitLink = styled(Link)`
  color: #000000;

  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  position: relative;
`;

export const IconWrapper = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ColumnTotalValues = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  b {
    margin-bottom: 4px;
    font-size: 12px;
  }
`;

export const ContainerButtonOpen = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  z-index: 12;
  bottom: -29px;
  min-width: 97px;
  border: 1px solid #E3E3E3;
  border-radius: 0px 0px 11px 11px;
  background: #FFFFFF;
  padding: 5px;
  overflow-x: hidden;
  gap: 5px;
  height: max-content;
  width: 100%;
  cursor: pointer;
`;
