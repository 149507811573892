import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { healthLevelColor } from '~/components/HealthIcon';

const getStatusColor = (isPrimary: boolean, status: number) => {
  if (isPrimary) {
    if (status === 1) {
      return { background: '#363BC4', border: '#363BC4', font: '#FFFFFF' };
    }

    return { background: '#B8B8B8', border: '#B8B8B8', font: '#FFFFFF' };
  }

  if (status === 1) {
    return { background: '#FFFFFF', border: '#3DD598', font: '#3DD598' };
  }

  return { background: '#FFFFFF', border: '#FF5454', font: '#FF5454' };
};

export const DataContainer = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: normal;
  width: 170px;
  /* margin: 0 15px; */
  margin-top: ${({ expanded }) => !expanded && '5px'};
`;

export const Title = styled.strong`
  font-size: 12px;
  margin-bottom: 2px;
`;

export const Subtitle = styled.span`
  font-size: 10px;
  font-weight: 500;
  color: #6D6D6D;
`;

export const RealTimeContainer = styled.div<{ dat?: boolean }>`
  padding-top: 10px;
  display: flex;
  flex-direction: column;

  > strong, span {
    font-size: 11px;
    color: ${({ dat }) => dat && '#B8B8B8'};
  }

  > span {
    padding-left: 2px;
    font-size: 17px;
    font-weight: bold;
    > span {
      font-weight: normal;
      font-size: 13px;
      color: '#B8B8B8';
    }

  }
`;

export const StatusContainer = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 4px;
  min-width: 160px;
  justify-content: flex-start;
`;

export const Dot = styled.div`
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1px;
`;

export const StatusBox = styled.div<{ isPrimary: boolean, status: number, dat?: boolean }>`
  width: 80px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({ isPrimary, status, dat }) => (dat ? '#B8B8B8' : getStatusColor(isPrimary, status).border)};
  background-color: ${({ isPrimary, status, dat }) => (dat ? '#FFFFFF' : getStatusColor(isPrimary, status).background)};
  border-radius: 4px;

  color: ${({ isPrimary, status, dat }) => (dat ? '#B8B8B8' : getStatusColor(isPrimary, status).font)};
  font-weight: bold;
  font-size: 9px;
  text-transform: uppercase;
`;

export const TransparentLink = styled(Link)`
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: inherit;
  &:hover {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
  }
`;

export const IconWiFiRealTime = styled.div`
display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;


  svg {
    width: 16px;
    height: 16px;
  }
`;
