import {
  useState, useEffect, useCallback, useRef,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import posthog from 'posthog-js';
import { getUserProfile } from '../../helpers/userProfile';

import {
  Container,
  UserOptions,
  Welcome,
  Expand,
  List,
  Item,
  ItemTitle,
} from './styles';
import { CelciusColorLogo, SpringerLogo } from '~/icons';
import MenuContext from '~/contexts/menuContext';
import { apiCall } from '~/providers';
import { getUserClientId } from '~/helpers/getClientsHelper';

const HeaderComp = ({ history }): JSX.Element => {
  const dispatch = useDispatch();
  const [focus, setFocus] = useState(false);
  const refContainer = useRef<any>(null);
  const [profile] = useState(getUserProfile);
  const me = profile.fullName;
  const { user } = profile;
  const hasToken = !!localStorage.getItem('@diel:token');
  const { menuToogle } = useContext(MenuContext);// true = open, false = not Open
  const handleClickOutside = useCallback((event) => {
    if (refContainer.current && !refContainer.current.contains(event.target)) {
      setFocus(false);
    }
  }, []);
  const [clientIds, setClientIds] = useState<number[] | null>(null);
  const logout = () => {
    localStorage.clear();
    posthog.reset();
    dispatch({ type: 'RESET_DATA' });
    history.push('/login');
  };

  useEffect(() => {
    getUserClientId({ profile, setClientIds });
  }, []);

  useEffect(() => {
    focus
      ? document.addEventListener('mousedown', handleClickOutside)
      : document.removeEventListener('mousedown', handleClickOutside);
  }, [focus, handleClickOutside]);

  return (
    <Container MenuOpen={menuToogle}>
      <div style={{ padding: menuToogle ? '0px 208px' : '0px 35px', marginLeft: '20px' }}>
        {/* 157 - Atacadão
        158 - Carrier */}
        {
          (clientIds && clientIds?.filter((item) => (item !== 157 && item !== 158)).length !== 0 || profile.permissions.isAdminSistema) && <CelciusColorLogo />
        }
        {
          (clientIds && clientIds?.filter((item) => (item !== 157 && item !== 158)).length === 0 && !profile.permissions.isAdminSistema) && <SpringerLogo color="black" />
        }
      </div>
      {/* <CustomLogo style={{ marginRight: '20px' }} src={CelciusLogoWhiteBallWhite} alt="logoDiel" /> */}
      {hasToken && (
        <UserOptions ref={refContainer} onClick={() => setFocus(!focus)}>
          <Welcome>
            Bem vindo,
            {' '}
            {me || user || ''}
          </Welcome>
          <Expand expanded={focus} />
          {focus && (
            <List>
              <Item onClick={() => history.push('/configuracoes')}>
                <ItemTitle>Configurações</ItemTitle>
              </Item>
              <Item onClick={logout}>
                <ItemTitle>Sair</ItemTitle>
              </Item>
            </List>
          )}
        </UserOptions>
      )}
    </Container>
  );
};

const Header = withRouter(HeaderComp);

export { Header };
