import {
  Slider,
  StateLabelChecked,
  StateLabelUnchecked,
  Cursor,
  Container,
  SliderMini,
  CursorMini,
} from './styles';

const variantWidth = {
  auto: 100,
  bloq: 90,
};

interface CProps {
  checked: boolean
  label?: string
  variant?: keyof typeof variantWidth
  sLabel?: string
}

export const ToggleSwitch = ({
  label = undefined, checked, variant = undefined, sLabel = undefined, ...props
}: CProps): JSX.Element => (
  <Container {...props}>
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <Slider checked={checked} variant={variant} width={(variant && variantWidth[variant]) || 44}>
        <Cursor checked={checked} variant={variant} />
        {(variant === 'auto') && (checked) && <StateLabelChecked width={variantWidth.auto}>AUTOMÁTICO</StateLabelChecked>}
        {(variant === 'auto') && (!checked) && <StateLabelUnchecked width={variantWidth.auto}>MANUAL</StateLabelUnchecked>}
        {(variant === 'bloq') && (checked) && <StateLabelChecked width={variantWidth.bloq}>{sLabel || 'LIBERADO'}</StateLabelChecked>}
        {(variant === 'bloq') && (!checked) && <StateLabelUnchecked width={variantWidth.bloq}>{sLabel || 'BLOQUEADO'}</StateLabelUnchecked>}
      </Slider>
      {label && <span style={{ paddingLeft: '8px' }}>{label}</span>}
    </div>
  </Container>
);

export const ToggleSwitchMini = ({
  label = undefined, checked, onOff = false, ...props
}): JSX.Element => (
  <Container {...props}>
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <SliderMini checked={checked}>
        <CursorMini onOff={onOff} checked={checked} />
      </SliderMini>
      {label && <span style={{ paddingLeft: '8px' }}>{label}</span>}
    </div>
  </Container>
);
