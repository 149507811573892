import { DriConfigState } from '~/pages/Analysis/Integrations/IntegrEdit/types';

export function isAutomation(state: DriConfigState): boolean {
  return state.application?.value === 'automation';
}

export function isFancoil(state: DriConfigState): boolean {
  return isAutomation(state) && state.driApplication === 'Fancoil';
}

export function isVAV(state: DriConfigState): boolean {
  return isAutomation(state) && state.driApplication === 'VAV';
}

export function isFancoilVavAutomation(state: DriConfigState): boolean {
  return isAutomation(state) && (isFancoil(state) || isVAV(state));
}

export function checkNewProtocolsValues(cfg, type: 'device'|'modbus', value: string) {
  if (cfg.protocol != null) {
    return cfg.protocol === value;
  }
  if (type === 'device') {
    return cfg['device-protocol'] === value;
  }
  return cfg['modbus-protocol'] === value;
}
